html, app-root, app-root > div, app-home, .view {
  height: 100%;
}
app-root, app-home {
  display: block;
}

@mixin cubic_transition($duration: 0.5s) {
  -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) $duration;
  -moz-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) $duration;
  -o-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) $duration;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) $duration;
}

@mixin ease_transition($duration: 0.5s) {
  -webkit-transition: all ease $duration;
  -moz-transition: all ease $duration;
  -o-transition: all ease $duration;
  transition: all ease $duration;
}

body {
  overflow: hidden;
  padding: 0;
  margin: 0;
  color: #fff;
  background-color: #000;
  font-family: arial;
  font-size: 100%;
  height: 100%;
  width: 100%;
}
a, a:hover, a:active, a:focus, button, button:hover, button:active, button:focus, input::-moz-focus-inner, button::-moz-focus-inner {
  outline: none !important;
}
:focus {outline:none !important;}
::-moz-focus-inner {border:0 !important;}

@font-face {
  font-family: "ituner";
  src: url("/css/fonts/ds-digii-webfont.eot"), url("/css/fonts/ds-digii-webfont.eot?#iefix") format("embedded-opentype"), url("/css/fonts/ds-digii-webfont.woff") format("woff"), url("/css/fonts/ds-digii-webfont.ttf") format("truetype");


  font-weight: normal;
  font-style: normal;
}

$topbar_height: 50px;
$border_d: 21;
$footer_height: 54px;
$border_color: rgb($border_d, $border_d, $border_d);

$screen-sm-min: 576px;


$screen-md-min: 768px;


$screen-lg-min: 992px;


$screen-xl-min: 1200px;


@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}


@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}


@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}


@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

.mat-drawer.sidebar-left {
  font-family: "Terminal Dosis", sans-serif;
  border-right: 3px solid #2fa1d6;
  background-color: black;
  bottom: $footer_height;


  .mainTitle {
    h2 {
      margin: 0;
    }
    .active {
      color: red;
      padding: 10px;
    }
  }

  .navigation {
    display: flex;
    flex-direction: row;


    line-height: 42px;
    width: 100%;
    margin: 0;
    padding: 0px;
     li {
      list-style: none;
      color: rgb(255, 255, 255);
      border-left: 1px solid $border_color;


      font-size: 12px;
      font-weight: 700;
      background: rgb(0, 0, 0);
      margin-left: 0;

      &.no_border {
        border-right: 0;
      }

      &.right {
        float: right;
        border-left: 1px solid $border_color;
      }

      &.logo a {
        padding-top: 5px;
      }

      &.current-anim {
        display: none;
        @include md {
          display: block;
        }
      }
    }
  }

  a {

    color: white;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 20px;
    display: block;
  }

  .animName {
    font-weight: bolder;
    font-style: italic;
  }

  .dropdown {
    display: block;
    z-index: 1;


    position: absolute;
    list-style: none;
    margin: 0px 0 0 0;


    li {
      border: 1px solid $border_color;
      display: inline-block;
      background: black;

    }
  }
}

.right-border {
  border-right: 1px solid $border_color;
}

#info {
  .top {
    position: absolute;
    top: $topbar_height;
    width: 100%;
    padding: 5px;
    text-align: center;
  }

  a {
    color: #66F;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    right: 5px;
    padding: 5px;
  }
}

.blue-hover {
  @include cubic_transition(0.5s);

  &:hover {
    color: rgb(74, 190, 255) !important;
  }

  a:hover {
    color: rgb(74, 190, 255);
  }
}

.mat-drawer.sidebar-right {

  border-left: 3px solid rgb(47, 161, 214);
  background-color: rgba(0, 0, 0, 1);
  font-family: "Terminal Dosis", sans-serif;
  bottom: $footer_height;
}

.side-buttons-container {
  color: rgb(238, 238, 238);
  text-shadow: 0 -1px 0 rgb(17, 17, 17);
  overflow: hidden;
  .button {


    ul {
      margin: 0px;
      padding-left: 6px;
    }

    li {
      list-style: none;
    }
  }
}

.folder {

  li.title {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 12px;
    line-height: 27px;
    padding-left: 16px;
    margin-left: 4px;
    border-left: 4px solid rgba(0, 0, 0, 0);
    background: url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlGIWqMCbWAEAOw==) 6px 10px no-repeat;
    cursor: pointer;
  }

  .title.open {
    background: url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlI+hKgFxoCgAOw==) 6px 10px no-repeat;
  }

  .content {
    margin-top: 1px;
    background-color: rgba(0, 0, 0, 1);
    margin-left: -6px;
    padding-top: 3px;
    border-top: 1px solid $border_color;
    font-size: 15px;

    > * {
      padding-left: 6px;
    }
  }
}

#container, .container-fluid {
  position: relative;
  min-height: 100%;
  width: 100%;
  padding:0;
}

spectrum {
  display: block;
  padding: 0px;
  margin: 0px;
  z-index: 5;
  height: 150px;

  canvas {
    width: 100%;
    background-color: #111;
    height: 100%;
    margin: 0px;
  }
}

#easter-egg {
  width: 400px;
  height: 100px;
  margin: 0px;
}

@mixin transition($rule) {
  -webkit-transition: $rule;
  -moz-transition: $rule;
  -o-transition: $rule;
  transition: $rule;
}


.playlist {


  li {
    cursor: pointer;
  }

  .active {
    text-shadow: 0 0 0;
    color: red;
    font-weight: bold;
    text-decoration: none;
  }
}

.links {
  margin: 5px;


  a {
    display: block;
    color: white;



  }

  a.active {
    color: red !important;
    font-weight: bolder;
  }
}

.slider {
  margin: 5px;

  .input {
    input {
      width: 100%;
      margin: 0px;
    }
  }
}

.controls canvas {
  background-color: white;
}

.box-show-setup {
  height: 0px;
}

.box-show-setup.box-show-start {
  height: 100%;
}

.playlist {
  .box {
    &.ng-hide-add {



    }
  }
}

.playlist-body {

}


.box {
  &.ng-hide-add, &.ng-hide-remove {
    display: block !important;
    overflow: hidden;

    @include ease_transition(0.3s);

    max-height: 500px;
  }

  &.ng-hide-add {

    opacity: 1;
  }

  &.ng-hide-add.ng-hide-add-active, &.ng-hide-remove {
    max-height: 0px;
    opacity: 0;
  }

  &.ng-hide-remove.ng-hide-remove-active {
    max-height: 500px;
    opacity: 1;
  }
}

.width-box {
  &.ng-hide-add, &.ng-hide-remove {
    @include ease_transition(0.1s);

    display: block !important;
    overflow: hidden;
  }

  &.ng-hide-add {
    max-width: 500px;
    max-height: 500px;
  }

  &.ng-hide-add.ng-hide-add-active, &.ng-hide-remove {
    max-width: 0px;
    max-height: 0px;
  }

  &.ng-hide-remove.ng-hide-remove-active {
    max-width: 500px;
    max-height: 500px;
  }
}

.slide-left-animation {
  &.ng-enter, &.ng-leave {
    @include cubic_transition;

    position: absolute;
    top: $topbar_height + 1;
    left: 0;
    right: 0;
    min-height: 1000px;
  }

  &.ng-enter {
    left: 100%;

    &.ng-enter-active {
      left: 0;
    }
  }

  &.ng-leave {
    left: 0;

    &.ng-leave-active {
      left: -100%;
    }
  }
}

.slide-right-animation {
  &.ng-enter, &.ng-leave {
    @include cubic_transition;

    position: absolute;
    top: $topbar_height + 1;
    left: 0;
    right: 0;
    min-height: 1000px;
  }

  &.ng-enter {
    left: -100%;

    &.ng-enter-active {
      left: 0%;
    }
  }

  &.ng-leave {
    left: 0%;

    &.ng-leave-active {
      left: 100%;
    }
  }
}

.controls {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;







  .flat-button {
    //background-repeat: no-repeat;
    //background-color: rgba(0, 0, 0, 0);
    //border: medium none;
    //display: inline-block;
    //opacity: 1;
    //outline: medium none;
    //padding: 0;
    //transition: background-color 0.218s ease 0s;
    //vertical-align: top;
    //cursor: pointer;
    //
    //&:hover {
    //  opacity: 1;
    //  background-color: #C7C7C7;
    //  color: #000;
    //}
    //
    //
    //height: $topbar_height;
    //width: $topbar_height;
    //color: #fff;
    //font-size: 15px;





    &[data-id=repeat][value="SINGLE_REPEAT"] {
      color: #2fa1d6;
    }




    &[data-id=shuffle][value="ALL_SHUFFLE"] {
      color: #2fa1d6;
    }















  }
}

.player_top {
  width: 100%;
  //margin-top: 5px;
  //margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  //margin-top: -6px;

  .time_duration {
    font-size: 12px;
    font-weight: 100;
    line-height: 10px;
  }
  #time_wrapper {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }



  #time {
    cursor: pointer;
    position: relative;
    height: 4px;
    outline: medium none;
    padding: 7px 0;

    /*visibility: hidden; */
    z-index: 602;

    &.active {
      visibility: visible;
    }

    .playing-progress-background {
      background-color: #CACACA;
      height: 4px;
    }
  }

  #progress {
    background-color: #2fa1d6;
    height: 4px;
    width: 0;
  }
}

.ps.ps--in-scrolling {
  &.ps--y > .ps__scrollbar-y-rail {
    background-color: transparent !important;
  }

  &.ps--x > .ps__scrollbar-x-rail {
    background-color: transparent !important;
  }
}

.ps:hover {
  &.ps--in-scrolling {
    &.ps--x > .ps__scrollbar-x-rail {
      background-color: transparent !important;
    }

    &.ps--y > .ps__scrollbar-y-rail {
      background-color: transparent !important;
    }
  }

  & > .ps__scrollbar-y-rail:hover {
    background-color: transparent !important;
  }
}
.all-wrap {
  min-height: 100%;
}

.page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  height:100%
}
mat-sidenav-container {
  height: 100%
}
.content > * {
  height: 100%;
  > * {
    height: 100%;

  }
}


/*
 * Make the component injected by <router-outlet> full height:
 */

main {

  display: flex;
  flex-direction: column;
  > *:not(router-outlet) {
    flex: 1;
    display: block;
  }

}
.footer {
  position:fixed;
  bottom:0px;
  text-align: center;
  background: black;
  width: 100%;
  z-index: 1;
  justify-content: center;
}
